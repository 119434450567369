import React from "react";
import Grid from "@mui/material/Grid";
import Illustration from "../Images/Illustration.png";
const BringSchoolLevel = () => {
  
  return (
    <div>
      <div className="Bring_school_main_div">
        <Grid className="common_main_grid">
          <Grid item md={2}>
            <div className="shcedlue_meeting_padding_both">
              <div className="Bring_school_padding_top">
                <h2 className="common_h2">
                  Bring Your school to the next{" "}
                  <span className="Bring_level_color"> level</span>
                </h2>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="shcedlue_meeting_padding_both">
              <div className="Bring_school_padding_top">
                <p className="Bring_school_para">
                  SkoolFirst is a one-of-a-kind software that uses cutting edge
                  technology to cater to the ever-evolving needs of educational
                  institutes. It is a cloud-based school management software
                  that digitises education and administrative processes in
                  schools, and functions of building a community of teachers,
                  students and parents to lead them into a world of better
                  opportunities and better possibilities. Employing a robust
                  approach in all their endeavours, SkoolFirst automates the
                  process of learning, imparting and managing education that
                  helps the institution restructure their traditional methods
                  and prepare them for a better tomorrow.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className="shcedlue_meeting_padding_both">
              <div id="svgContainer"></div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BringSchoolLevel;
