import React from "react";

const FeatureCard = (props) => {
  return (
    <div>
      <div className="feature_card_padding">
        <div>
          <img
            src={props.Image}
            alt="no_image"
            className="feature_count_image"
          />
        </div>
        <div>
          <p className="feature_cloud_plateform">{props.heading}</p>
          <p className="feature_para_font">{props.para}</p>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
