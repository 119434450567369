import React from "react";
import "./WebsiteHomepage.css";
import HOC from "../HOC/HOC";
import ScheduleMeeting from "./ScheduleMeeting";
import OurCustomar from "./OurCustomar";
import BestSchool from "./BestSchool";
import Faqs from "./Faqs";
import FeatureAnalyises from "./FeatureAnalyises";
import Pricing from "./Pricing";
import AboutUs from "./AboutUs";
import BringSchoolLevel from "./BringSchoolLevel";
import ContactUs from "./ContactUs";


const WebsiteHomepage = () => {
  return (
    <div className="website_home_main_div">
      {/* -----meeting section --- */}
      <section id="home">
        <ScheduleMeeting />
      </section>
      <section>
        <BringSchoolLevel />
      </section>
      {/* -----fetaure count section--- 
      <section>
        <OurCustomar />
      </section>
      */}
      {/* -----BestSchool section --- */}
      <section>
        <BestSchool />
      </section>
      {/* -----feature section --- */}
      <section id="features">
        <FeatureAnalyises />
      </section>
      {/* -----About Us section --- */}
      <section id="about_us">
        <AboutUs />
      </section>
      {/* -----FAQs section --- */}
      <section id="faq">
        <Faqs />
      </section>
      {/* ------Pricing section------- */}
      <section id="pricing">
        {" "}
        <Pricing />
      </section>
      {/* --------contact us---------- */}
      <section id="contact_us">
        <ContactUs />
      </section>
    </div>
  );
};

export default HOC(WebsiteHomepage);
