import React from "react";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";


const Map = withScriptjs(
  withGoogleMap((props) => {
    return (
      <GoogleMap defaultCenter={props.defaultCenter} defaultZoom={11}>
        <Marker position={props.defaultCenter} />
      </GoogleMap>
    );
  })
);

const AppMap = (props) => {
  return (
    <Map
      googleMapURL={
        "https://maps.googleapis.com/maps/api/js?key=" +
        "AIzaSyCLBNpQEw9Zb41U5s61hCJ1rxhP5WJzUAk" +
        "&v=3.exp&libraries=geometry,drawing,places"
      }
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: "80vh" }} />}
      mapElement={<div style={{ height: `100%` }} />}
      defaultCenter={{ lat: 12.91634, lng: 77.6518 }}
      defaultZoom={11}
      
    />
  );
};

export default AppMap;
