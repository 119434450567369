import WebsiteHomepage from "./Components/WebsiteHomepage/WebsiteHomepage";
import TermsAndCondition from "./Components/TermsAndCondition/TermsAndCondition";
import PrivacyPolicy from "./Components/Privacy_policy/privacy_policy";
import { Routes, Route } from "react-router-dom";
function App() {
  return (
    <Routes >
      <Route exact path="/" element={<WebsiteHomepage />} />
      <Route exact path="/TermsAndCondition" element={<TermsAndCondition />} />
      <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
    </Routes >
  );
}

export default App;
