import React from "react";
import Grid from "@mui/material/Grid";
import timer_icon from "../Images/Icon/timer.svg";
import tag_faces from "../Images/Icon/tag-faces.svg";

const AboutUs = () => {
  return (
    <div>
      <div className="about_us_div_padding">
        <Grid className="common_main_grid ">
          <Grid md={2}>
            <div className="shcedlue_meeting_padding_both">
              <h1 className="about_us_heading">About Us</h1>
            </div>
          </Grid>
          <Grid md={5}>
            <div className="shcedlue_meeting_padding_both">
              <p className="about_us_paragraph">
                The education sector is rapidly evolving and headed towards the
                era of virtual classes. With the fast changing education
                policies brought about by the World Economic Forum Report on
                “Future of Jobs”, it has become absolutely essential to move
                away from traditional methods of school management and imbibe
                technology in the way we impart and manage education. With
                SkoolFirst, you get an opportunity to automate the way your
                institution operates and make the processes more streamlined. We
                recognise the importance of innovation and make deliberate
                attempts to employ it in our approach. We help you make the
                necessary changes towards reducing human involvement and
                bringing in more digital ways of handling school administration
                and operations.
              </p>
            </div>
          </Grid>
          <Grid md={5}>
            <div className="shcedlue_meeting_padding_both">
              <div className="about_us_div">
                <div className="about_us_icon">
                  <img src={timer_icon} alt="No_Profile" />
                </div>
                <div className="about_right_data">
                  <h3 class> More time</h3>
                  <p>
                    Automating time-consuming and document-heavy processes so
                    that institutions can devote more time towards improving the
                    quality of education.
                  </p>
                </div>
              </div>
              <div className="about_us_div">
                <div className="about_us_icon">
                  <img src={tag_faces} alt="No_Profile" />
                </div>
                <div className="about_right_data">
                  <h3>Best Communication</h3>
                  <p>
                    Creating an ecosystem of parents, students and teachers so
                    that they can communicate frequently, stay in touch with new
                    policies and events and have data related to results and
                    feedback at the click of the button.
                  </p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AboutUs;
