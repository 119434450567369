// faqs data array
export const FaqsArray = [
  {
    Heading: "How easy or difficult is it to install the software?",
    para: "The software requires a basic and very minimal initial setup. Since this is cloud-based software, it does not require a heavy installation process. Once it is set up and configured as per the requirement of your institution, it can be put into use immediately.",
    show: true,
    Sno: "1",
  },
  {
    Heading:
      "Is it possible to onboard the existing students and faculty members to the software?",
    para: "The software is here to help you streamline your existing processes and those you will need in the future. It supports the onboarding process of existing students and also gives you a seamless experience while uploading their data onto the software. This  is one of the biggest advantages of using SkoolFirst.",
    show: false,
    Sno: "2",
  },
  {
    Heading:
      "How safe is it to use the software? Is there a threat to the data uploaded?",
    para: "The software uses end-to-end encryption which makes your data absolutely secure. None of your personal  information is disclosed as  your privacy is of utmost importance to us.",
    show: false,
    Sno: "3",
  },
  {
    Heading:
      "Do you have different access portals for students, teachers and parents?",
    para: "Yes, there is a different portal for each of these categories. SkoolFirst will provide you with your unique login details and you can use that to sign in to your respective portal.",
    show: false,
    Sno: "4",
  },
  {
    Heading: "What is the pricing structure like?",
    para: "We have different modules for different kinds of requirements. To know, kindly contact us using the contact form or drop us a mail at (your email id).",
    show: false,
    Sno: "5",
  },
  {
    Heading: "How many platforms can the software support?",
    para: "The software is configured to support multiple platforms. We work seamlessly on the web and mobile phones, running on iOS and Android.",
    show: false,
    Sno: "6",
  },
  {
    Heading:
      "Can the software be customised to suit the requirements of my institution?",
    para: "Yes, the software will accommodate the unique requirements of your institute. A lot of research has gone in to come up with different modules for different educational needs. Please contact us to know more about this.",
    show: false,
    Sno: "7",
  },
  {
    Heading: "Can the same software be used across multiple branches?",
    para: "Yes, the software is cloud-based and virtual. All you need is the unique login detail and a running internet connection and you can use the software in any branch, domestic or  international.",
    show: false,
    Sno: "8",
  },
  {
    Heading:
      "Is there some level of training required to operate this software?",
    para: "The software is very  easy to operate and requires basic knowledge of usage of  computers and the internet. That being said, we will give you an introduction to the software and help you with the initial setups. You can always contact us in case you need additional help at a  later stage.",
    show: false,
    Sno: "9",
  },
  {
    Heading: "Do you provide after-sale services?",
    para: "Yes, we do. Our customers are our most valuable asset and we want them to have a seamless experience with the software. The cost of after-sale services will already be included so no additional charges will be levied.",
    show: false,
    Sno: "10",
  },
];
