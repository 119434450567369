import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import DesktopHero from "../Images/DesktopHero.png";

const BestSchool = () => {
  const [schoolListArray, setSchoolListArray] = useState([
    {
      heading: "A cloud-based virtual platform",
      para: "Streamlines the school management ensuring better education delivery and practising more control over school operational activities.",
      show: true,
    },
    {
      heading: "Library management (Coming soon) ",
      para: "Virtual library management focuses on tracking the issue and return of the library books, adding new books to the collection and discarding books no longer in use.",
      show: false,
    },
    {
      heading: "Flawless communication",
      para: " Digital noticeboard so that the students stay up to date with the latest updates on school administration, changes in policies, curriculum, exams, the status of results and many more.",
      show: false,
    },
    {
      heading: "Systematic communication",
      para: "Personalised SMS and email support to make communication between students, teachers and parents seamless and convenient. Also, minimising human intervention to avoid any loss of information.",
      show: false,
    },
    {
      heading: "Feedback and results",
      para: "Providing virtual, one-on-one feedback and results to inculcate a positive and affirmative exchange of ideas and motivating students the right way.",
      show: false,
    },
  ]);
  const handleChange = (selectedIndex) => {
    for (let index = 0; index < schoolListArray.length; index++) {
      if (index === selectedIndex) schoolListArray[index].show = true;
      else {
        schoolListArray[index].show = false;
      }
    }
    setSchoolListArray([...schoolListArray]);
  };
  return (
    <div>
      <div className="best_school_main_div">
        <div className="best_school_heading">
          <h2 className="common_h2">
            Best <span className="Bring_level_color"> school software </span>
            on <br />
            the market
          </h2>
        </div>
        <Grid className="common_main_grid">
          <Grid item md={4}>
            <div className="shcedlue_meeting_padding_both">
              {schoolListArray.map((row, index) => (
                <div className="">
                  <Accordion
                    className={
                      row.show === true
                        ? "bestshool_hide_data"
                        : "best_school_shadow"
                    }
                    expanded={row.show}
                    onClick={() => handleChange(index)}
                  >
                    <AccordionSummary
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      {" "}
                      <Typography
                        className={
                          row.show === true
                            ? "bestshool_show_data"
                            : "bestcshool_heading"
                        }
                      >
                        {row.heading}:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="best_school_para">{row.para}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </div>
          </Grid>
          <Grid item md={8}>
            <div className="shcedlue_meeting_padding_both">
              <div className="blank_school">
                <img
                  src={DesktopHero}
                  alt="no_profile"
                  className="schedule_mobile_logo"
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BestSchool;
