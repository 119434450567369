import React from "react";
import "./HOC.css";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Logo from "../Images/Logo.png";

const HomeHeader = () => {
  return (
    <div>
      <div>
        <AppBar position="fixed" className="home_header_background">
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              <img src={Logo} alt="No-logo" className="header_logo_color" />
            </Typography>
            <div className="header_home_flex_content" />
            <div className="header_flex_grow">
              <p>
                <a href="/#home"> Home</a>
              </p>
              <p>
                <a href="/#features"> Features</a>
              </p>
              <p>
                <a href="/#about_us">About Us</a>
              </p>
              <p>
                <a href="/#faq">FAQs</a>
              </p>
              <p>
                <a href="/#pricing">Pricing</a>
              </p>
              <p>
                <a href="/#contact_us">Contact Us</a>
              </p>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
};

export default HomeHeader;
