import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
const Pricing = () => {
  return (
    <div>
      <div className="common_div_padding">
        <div className="best_school_heading">
          <h2 className="common_h2">Pricing</h2>
        </div>
        <div>
          <Grid className="common_main_grid ">
          {/*  
            <Grid md={4}>
              <div className="pricing_padding_both">
                <Card className="pricing_white_card_shadow">
                  <div className="Prcing_card_center">
                    <h3>SkoolFirst Basic</h3>
                    <div>
                      <span className="prcing_amout">22$</span>
                      <span className="pricing_year"> /year </span>
                    </div>
                    <div className="pricing_hr">
                      <hr />
                    </div>
                    <p>
                      Our basic package is mostly for small institutions,
                      kindergartens and preschools. It supports a single admin
                      and can be used across various branches
                    </p>
                    <div className="pricing_button_padding">
                      <button>Schedule a Meeting</button>
                    </div>
                  </div>
                </Card>
              </div>
            </Grid>
            */}
            <Grid md={4}>
              <div className="shcedlue_meeting_padding_both">
                <div className="prcing_valur_right_set">
                  <div className="pricing_best_value">
                    <span>Best Values</span>
                  </div>
                  <div>
                    <Card className="pricing_blue_card_shadow">
                      <div>
                        {/* <div className="pricing_best_value">
                      <h4 >Best Values</h4>
                    </div> */}
                        <div className="Prcing_card_center">
                          <h3>SkoolFirst Premium</h3>
                          <div>
                            {/*<span className="prcing_amout">100$</span>
                            <span className="pricing_year"> /year </span>
                            */}
                          </div>
                          <div className="pricing_hr">
                            <hr />
                          </div>
                          <p>
                            
                            <b>Currently Promo pricing available with 3 months trial</b>
                            <br></br>
                            <br></br>
                            Our package caters for medium and large institutions like schools, tuition centers having multiple faculty, academic session, and simplify the administrative process


                          </p>
                          <div className="pricing_button_padding">
                            <button>Contact us at contact@epalsoftware.com</button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </Grid>
            {/*
            <Grid md={4}>
              {" "}
              <div className="pricing_padding_both">
                <Card className="pricing_white_card_shadow">
                  <div className="Prcing_card_center">
                    <h3>SkoolFirst Enterprise</h3>
                    <div>
                      <span className="prcing_amout">100$</span>
                      <span className="pricing_year"> /year </span>
                    </div>
                    <div className="pricing_hr">
                      <hr />
                    </div>
                    <p>
                      Enterprise package is for large institutions like
                      international schools, who heavily rely on a smooth
                      administrative process. The module can be used by multiple
                      admins.
                    </p>
                    <div className="pricing_button_padding">
                      <button>Schedule a Meeting</button>
                    </div>
                  </div>
                </Card>
              </div>
            </Grid>*/}    
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
