import React from 'react'
import HOC from "../HOC/HOC";
const privacy_policy = () => {
    return (
        <div className="website_home_main_div">
            Privacy policy
        </div>
    )
}

export default HOC(privacy_policy)
