import React from "react";
import Grid from "@mui/material/Grid";
import GoogleMap from "./GoogleMap";
const ContactUs = () => {
  return (
    <div>
      <div className="Contact_us_main_div">
        <h2 className="Faq_heading">Contact Us</h2>
        <div className="contact_grid_padding">
          <Grid className="common_main_grid">
            <Grid item md={2}></Grid>
            <Grid item md={10}>
              <div className="position_absolute">
                <div className="contactus_left_card" style={{ zIndex: 99 }}>
                  <div className="contact_address_data">
                    <p>No. 334, 4th Floor, 27th Main Road</p>
                    <p>2nd Sector, HSR Layout</p>
                    <p>Bengaluru, Karnataka 560102</p>
                    <p>Email: contact@epalsoftware.com</p>
                  </div>
                </div>
              </div>
              <div>
                <div  className="shcedlue_meeting_padding_both padding_left"> <GoogleMap /></div>
               
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
