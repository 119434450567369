import React from "react";
import Grid from "@mui/material/Grid";
import "./WebsiteHomepage.css";
import "../../Common.css";
import TextField from "@mui/material/TextField";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import MobileHero from "../Images/MobileHero.png";
const ScheduleMeeting = () => {
 // const [startDate, setStartDate] = useState(new Date());
  return (
    <div>
      <div className="sechedule_meeting_main_div">
        <Grid className="common_main_grid">
          <Grid item md={6}>
            <div className="shcedlue_meeting_padding_both">
              <div className="schedule_meeting_digital">
                <h1>
                  Digitally transforming the world of <span> education </span>
                </h1>
                <p>
                  {" "}
                  Tailor-made, customisable solutions for your educational
                  institution that helps you to reduce the load of documentation
                  on your faculty and administrative staff, forms a network for
                  students, teachers and parents and let’s you deliver quality
                  education in a technologically advanced way.
                </p>
              </div>
              <div>
                <img
                  src={MobileHero}
                  alt="no_image"
                  className="schedule_mobile_logo"
                />
              </div>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className="shcedlue_meeting_padding_both">
              <div className="schdeule_right_grid_width">
                <div className="schedule_padding_filed">
                  <TextField
                    id="standard-basic"
                    label="Full name"
                    variant="standard"
                    className="common_textfield_width"
                  />
                </div>
                <div className="schedule_padding_filed">
                  <TextField
                    id="standard-basic"
                    label="Phone number"
                    variant="standard"
                    className="common_textfield_width"
                  />
                </div>
                <div className="schedule_padding_filed">
                  <TextField
                    id="standard-basic"
                    label="Email Address"
                    variant="standard"
                    className="common_textfield_width"
                  />
                </div>
                <div className="schedule_padding_filed">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      disableFuture
                      openTo="year"
                      format="dd/MM/yyyy"
                      label="Date"
                      views={["year", "month", "date"]}
                      // value={startDate}
                      // onChange={(e) => {
                      //   setStartDate(e);
                      // }}
                      className="common_textfield_width"
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="schedule_padding_filed">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      disableFuture
                    //  openTo="year"
                      format="HH:MM:SS"
                      label="Time"
                     // views={["hours", "minute", "second"]}
                      // value={startDate}
                      // onChange={(e) => {
                      //   setStartDate(e);
                      // }}
                      className="common_textfield_width"
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="schedule_button_fix">
                  <button className="common_button">Schedule a Meeting</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ScheduleMeeting;
